<template>
  <div id="usuarios">
    <MenuBase
      :tipo="'usuarios'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-clipboard-account'"
      :verTabla="verTabla"
      :loading="!$store.state.tabla.length"
    />
    <Usuario :modo.sync="modoUsuario" :titulo.sync="tituloUsuario" :dialogPU.sync="dialogUsuario"/>
    <v-overlay :value="loading" :z-index="9999">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Usuario from '@/views/Paciente/pacienteusuario.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Usuario
  },
  data: () => ({
    headers: [],
    botones: [],
    opciones: [],
    titulo: 'Usuarios',
    tituloOpciones: 'Opciones cuenta: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    verTabla: false,
    modoUsuario: '',
    tituloUsuario: '',
    dialogUsuario: false,
    labelActivado: '',
    colorActivado: '',
    loading: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'TIPO', align: 'start', value: 'tipo' },
        { text: 'RUT', align: 'start', value: 'rut' },
        { text: 'PERSONA', align: 'start', value: 'persona' },
        { text: 'ESTADO', align: 'start', value: 'estado' },
        { text: 'SUCURSAL', align: 'start', value: 'sucursal' },
        { text: 'USUARIO', align: 'start', value: 'usuario' }
      ]
      if (this.returnPrilegio('usuarios-tabla') === true) {
        this.verTabla = true
      }
      if (this.returnPrilegio('usuarios-datospropios') === true) {
        this.botones.push({ id: 1, nombre: 'DATOS PROPIOS', color: 'primary', accion: 'datosPropios' })
      }
      if (this.returnPrilegio('usuarios-botonnuevo') === true) {
        this.botones.push({ id: 2, nombre: 'NUEVO USUARIO', color: 'primary', accion: 'nuevoUsuario' })
      }
    },
    clicFila (fila) {
      this.campoOpcion = fila.usuario_cuenta
      this.$store.commit('cambiarID', fila.id)
      this.cambiarActivacion(fila.estado)
    },
    clicBoton (accion) {
      if (accion === 'nuevoUsuario') {
        this.modoUsuario = 'nuevousuario'
        this.tituloUsuario = 'Nuevo Usuario'
        this.dialogUsuario = true
      } else if (accion === 'editarUsuario') {
        this.modoUsuario = 'editarusuario'
        this.tituloUsuario = 'Editar Usuario'
        this.dialogUsuario = true
      } else if (accion === 'activarUsuario') {
        this.estadoUsuario()
      } else if (accion === 'datosPropios') {
        this.modoUsuario = 'datospropios'
        this.tituloUsuario = 'Datos Propios'
        this.dialogUsuario = true
      }
    },
    estadoUsuario () {
      if (this.colorActivado === 'success') {
        if (confirm('¿Está seguro que desea activar a este usuario?')) {
          this.cambiarEstadoUsuario('Activado')
        } else {}
      } else {
        if (confirm('¿Está seguro que desea desactivar a este usuario?')) {
          this.cambiarEstadoUsuario('Desactivado')
        } else {}
      }
    },
    async cambiarEstadoUsuario (estado) {
      this.loading = true
      const ins = {
        ID: this.$store.state.ids,
        Estado: estado
      }
      const response = await ApiCon.post('cuentas/cambiarestado', ins)
      if (response.status === 200) {
        alert('El estado del usuario ha sido cambiado')
        this.cambiarActivacion(estado)
        this.cargarItems()
      } else { alert('error cambiarEstadoUsuario') }
      this.loading = false
    },
    cambiarActivacion (estado) {
      if (estado === 'Activado') {
        this.labelActivado = 'DESACTIVAR USUARIO'
        this.colorActivado = 'error'
      } else {
        this.labelActivado = 'ACTIVAR USUARIO'
        this.colorActivado = 'success'
      }
      this.opciones = []
      this.opciones = [
        { id: 1, nombre: 'EDITAR USUARIO', color: 'primary', accion: 'editarUsuario' },
        { id: 2, nombre: this.labelActivado, color: this.colorActivado, accion: 'activarUsuario' }
      ]
    },
    cargarItems () {
      this.cargarTabla('cuentas/todos', 'cambiarTabla', 'cargarCuentas')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('usuarios') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created: function () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
